<script setup lang="ts">
import type { ResourcePreview } from '~/integrations/resources/types';

const props = withDefaults(
  defineProps<{
    collectionId?: string;
    queryId?: string;
    resourcePreview: ResourcePreview;
    suppressTracking?: boolean;
  }>(),
  {
    collectionId: undefined,
    queryId: undefined,
    suppressTracking: false,
  }
);

const localePath = useLocalePath();
const { trackResourcePreviewClick } = useAlgoliaInsightsTracking();

const routeLocation = computed(() => {
  const routeBase = props.resourcePreview.resourceType.slug === 'collection' ? 'collections' : 'resources';
  const idSlug = props.resourcePreview.idSlug;
  const slug = props.resourcePreview.slug;
  return {
    path: localePath(`/${routeBase}/${idSlug}/${slug}`),
    query: {
      queryId: props.queryId,
      collectionId: props.collectionId,
    },
  };
});

function trackClick() {
  if (props.suppressTracking) {
    return;
  }

  trackResourcePreviewClick({
    id: props.resourcePreview.id,
    type: props.resourcePreview.resourceType.slug,
  });
}
</script>

<template>
  <div class="flex flex-col lg:w-72">
    <NuxtLink :to="routeLocation" class="hover:no-underline" @click="() => trackClick()">
      <ResourcePreviewImage
        is-gallery-view
        show-duration
        :resource-title="resourcePreview.title"
        :resource-type="resourcePreview.resourceType"
        :route-location="routeLocation"
        :duration-in-minutes="resourcePreview.durationInMinutes"
        :image="props.resourcePreview.thumbnailImageObject"
      />
    </NuxtLink>
    <NuxtLink :to="routeLocation" @click="() => trackClick()">
      <h3 class="h3 -mt-2 hover:underline xs:mt-0">{{ resourcePreview.title }}</h3>
    </NuxtLink>
  </div>
</template>
